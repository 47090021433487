@mixin fz($size) {
    .fz-#{$size}{
        @if $size > 13{
            font-size: ($size - 1)+px !important;
            line-height: $size+2+px !important;
        }
        @else{
            font-size: $size+px !important;
            line-height: $size+2+px !important;
        }
        @media (min-width:375px) {
            font-size: $size+px !important;
            line-height: $size+4+px !important;
        }
    }
}

@include fz(10);
@include fz(11);
@include fz(12);
@include fz(13);
@include fz(14);
@include fz(15);
@include fz(16);
@include fz(18);
@include fz(20);
@include fz(25);
@include fz(30);
