.TaskDetailsContainer {
  flex:1;
  padding: 30px 15px;
  overflow-y: auto;
  position: relative;
  @media (min-width:576px) {
    padding:30px;
  }
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.taskDetailsHead{
  padding: 15px;
  display: flex;
  align-items: center;
  z-index: 1;
  // @media (min-width:992px) {
  //   flex-direction: row;
  // }
  @media (min-width:576px) {
    padding: 30px;
  }
}

.taskTitle{
  padding-bottom: 8px;
  padding-right: 20px;
  border-bottom: 1px solid black;
  width: auto;
  display: inline-block;
  height: auto;
  font-size: 22px;
  line-height: 26px;
  font-weight: normal;
  z-index: 2;
  max-width: 50%;
  @media (min-width:992px) {
    max-width: 70%;
    margin-right: 25px;
  }
}

.TaskEmptyImage{
  opacity: 0.5;
  margin-top:auto;
  margin-bottom: 20px;
  margin-right:20px;
  object-fit: contain;
  align-self: flex-end;
  font-weight: bold;
  color:white;
  text-align: right;
  position:absolute;
  right:20px;
  bottom:20px;
}
