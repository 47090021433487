@import '../ForgotPassword/forgotPassword.module.scss';

.signupCopy {
  color: #fff !important;
}

.dropDown {
    background-color: #fff !important;
    color: #000;
    border: none !important;
    font-family: "Gotham";
    font-size: 11px;
}

.dropDown::placeholder {
    font-family: "Gotham Bold";
    color: #99CEC5;
}
