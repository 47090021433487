@import './colors';

@mixin theme ($name, $default_white, $default_black, $default_yellow, $default_gray, $default_blue_bahama, $default_biscay, $default_malibu) {
    .#{$name}{
        &[class*='button']{
					background-color: #000;
					color: #fff;
					border: 2px solid #000;
					font-family: "Gotham Bold";
        }
        &[class*='button'][class*='empty']{
            background-color: transparent;
            color: #000;
            font-weight: bold;
            font-family: "Gotham Bold";
        }
        &[class*='button-secondary']{
					background-color: #000;
					color: #fff;
					border: 2px solid #000;
					font-family: "Gotham Bold";
        }
				&[class*='button-userprofile']{
					background-color: #000;
					color: #fff;
					border: 2px solid #000;
					font-family: "Gotham Bold";
        }
        &[class*='button-active']{
            background-color: #fff;
            color: #00abef;
            border: 2px solid #00abef;
            width: 140px !important;
            font-family: "Gotham Bold";
        }
        &[class*='button-inactive']{
            background-color: #00abef;
            color: #fff;
            border: 2px solid #00abef;
            font-family: "Gotham Bold";
        }
        &[class*='inputText']{
            background-color: #fff !important;
            color: $default_black;
            border: none !important;
            font-family: "Gotham";
            font-size: 11px;
        }
        &[class*='inputText']::placeholder {
            font-family: "Gotham Bold";
            color: #000;
        }
        &[class*='inputMessage']{
            box-shadow: 0 0 3px rgba($default_black, .2);
        }
        &[class*='wrapper-sign']{
            background-color: #009cff;
						background-size: cover;
						background-image: url("https://www.heyjudeapp.com/img/firstBanner.jpg");
        }
        &[class*='wrapper-type-switch']{
            box-shadow: 0 0 10px rgba($default_black, .1);
        }
        &[class*='wrapper-creation-options']{

        }
        &[class*='wrapper-main-app']{
            background-color: $default_white;
        }
        &[class*='FPContainer']{
          font-family: "Gotham Bold";
            >[class*='FPBox']{
                color: $default_gray;
                background-color: $default_white;
                font-family: "Gotham Bold";
                >[class*='FPSelect']{
                    box-shadow: 0 0 3px rgba($default_black, .3);
                    font-family: "Gotham Bold";
                }
            }
        }
        &[class*='titleBar']{
            background-color: #000;
            color: $default_gray;
            box-shadow: 0 0 10px rgba($default_black, .2);
        }
        &[class*='hamburger__item']{
            background-color: #FFF;
        }
        &[class*='taskCard']{
            background-color: $default_white;
            box-shadow: 0 0 20px rgba($default_blue_bahama, .1);
            >[class*='taskCard__left']{
                >[class*='taskCard__date']{
                    color: #00abef;
                    font-weight: bold;
                    font-family: "Gotham Bold";
                }
                >[class*='taskCard__month']{
                    color: #00abef;
                    font-weight: bold;
                    font-family: "Gotham Bold";
                }
            }
            >[class*='taskCard__right']{
                border-color: rgba($default_black, .2);
                >[class*='taskCard__title']{
                    color: #82858b;
                    font-family: "Gotham";
                }
                >[class*='taskCard__status']{
                    color: #948c82 !important;
                    font-family: "Gotham";
                }
            }
            >[class*='taskCard__arrow']{
                border-color: rgba($default_black, .4);
            }
        }
        &[class*='taskListColumn']{
            border-color: rgba($default_black, .5);
        }
        &[class*='messageUser']{
            border: 1px solid #efefef;
            background-color: #efefef;
            color: #000;
						text-align: left;
        }
        &[class*='messageApp']{
            border: 1px solid #00abef !important;
            background-color: #00abef;
            color: #fff !important;
						font-weight: bold;
        }
        &[class*='messageInputContainer']{
            background-color: $default_white;
            box-shadow: 0 0 10px rgba($default_black, .1);
        }
        &[class*='taskDetailsHead']{
            box-shadow: 0 0 10px rgba($default_black, .1);
            // background-color: rgba($default_gray, .1);
            // @media (min-width:992px) {
                //     background-color: transparent;
                // }
            >[class*='taskDetailsHeadText']{
                color: $default_gray;
                border-color: rgba($default_gray, .3);
            }
        }
        &[class*='userProfileContainer']{
            color: #000;
            background-color: #948c82;
            background-size: cover;
        }
        &[class*='chatArea']{
            background-color: $default_white;
        }
        &[class*='NewTaskContainer']{
            background-color: rgba($default_white, .9);
            >[class*='NewTaskBlock']{
                background-color: $default_white;
                color: $default_gray;
                >[class*='NTClose']{
                    color: $default_gray;
                }
            }
        }
        &[class*='NewTaskMediaAttachmentList']{
            border-color: transparent;
        }
        &[class*='MediaAttachmentList']{
            background-color: rgba($default_blue_bahama, .7);
        }
        &[class*='MediaAttachmentListItem']{
            background-color: $default_gray;
            border-color: $default_white;
        }
        &[class*='AlertMessageContainer']{
            border-color: $default_yellow;
        }
        &[class*='AlertMessagePage']{
            color: $default_black;
        }
        &[class*='AlertLoader']{
            border-color: $default_white;
            border-top-color: $default_yellow;
        }

        &[class*='icon']{
            color: inherit;
        }
        &[class*='gothamBold'] {
          font-family: "Gotham Bold";
        }
    }
}

@include theme('default', $default_white, $default_black, $default_yellow, $default_gray, $default_blue_bahama, $default_biscay, $default_malibu);
