.AlertLoaderPage {
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  padding:10px;
  z-index: 1000;
}

.AlertLoaderContainer{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border-color: white;
  display:table;
  padding:20px;
  border-radius: 10px;
  margin:0px auto;
}

.AlertLoader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;
  align-self: center;
  margin:0px auto;
  margin-bottom: 20px;

}

.AlertLoaderText {
color:#000;
text-align: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
