@mixin space($name, $val) {
    @if $name == mt{
        .mt{
            &-#{$val}{
                margin-top: $val+px;
            }
        }
    }
    @else if $name == ml{
        .ml{
            &-#{$val}{
                margin-left: $val+px;
            }
        }
    }
    @else if $name == mr{
        .mr{
            &-#{$val}{
                margin-right: $val+px;
            }
        }
    }
    @else if $name == p{
        .p{
            &-#{$val}{
                padding: $val+px !important;
            }
        }
    }
    @else if $name == pr{
        .pr{
            &-#{$val}{
                padding-right: $val+px;
            }
        }
    }
    @else if $name == pl{
        .pl{
            &-#{$val}{
                padding-left: $val+px;
            }
        }
    }
    @else if $name == pt{
        .pt{
            &-#{$val}{
                padding-top: $val+px;
            }
        }
    }
    @else if $name == pb{
        .pb{
            &-#{$val}{
                padding-bottom: $val+px;
            }
        }
    }
}




@include space('ml', 10);
@include space('ml', 15);
@include space('ml', 25);

@include space('mr', 10);
@include space('mr', 15);

@include space('mt', 3);
@include space('mt', 5);
@include space('mt', 10);
@include space('mt', 15);
@include space('mt', 20);
@include space('mt', 30);
@include space('mt', 50);
@include space('mt', 80);


@include space('p', 5);
@include space('p', 10);

@include space('pr', 5);

@include space('pt', 30);
@include space('pt', 50);

@include space('pb', 30);