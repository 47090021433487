.inputText {
  flex:1;
  font-size: 16px;
  overflow: hidden;
  border: 1px solid #fff;
  font-family: 'Gotham';
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  color: #000 !important;
  font-weight: bold;
  height: 100px;
	border: 1px solid #666 !important;
}

.inputText::placeholder {
  font-size: 14px;
  color: #ccc !important;
  font-family: "Gotham";
}

.inputMessage{
  height: 40px;
  flex: 1;
  border-radius:5px;
}
