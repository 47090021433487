.hamburger{
    width: 40px;
    height: 40px;
    position: relative;
    &__item{
        display: block;
        width: 20px;
        height: 3px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 10px;
        &::before, &::after{
            content: '';
            display: block;
            width: 20px;
            height: 3px;
            position: absolute;
            left: 0;
            top: 0;
            background-color: inherit;
            border-radius: 10px;
        }
        &::before{
            transform: translateY(-7px);
        }
        &::after{
            transform: translateY(7px);
        }
    }
}