.inputText {
  /* display:flex; */
  /* margin:10px; */
  padding: 0px 10px;
  height:40px;
  /* width:240px; */
  font-size: 12px;
  border-radius:8px;
  border:none !important;
}

.inputMessage{
  height: 40px;
  flex: 1;
  border-radius:5px;
}
