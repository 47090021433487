.taskCard{
  width: 100%;
  min-height: 90px;
  position: relative;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
  border: none;
  margin-bottom: 20px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #efefef !important;
  color: #ccc;

  &__left{
    padding-right: 10px;
    min-width: 60px;
  }
  &__right{
    padding: 5px 0;
    border-left: 1px solid #000;
    padding-left: 10px;
    padding-right: 15px;
    text-align: left;
  }

  &__arrow{
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    right: 13px;
    top: 50%;
    border-top: 2px solid black;
    border-right: 2px solid black;
    transform: translateY(-50%) rotate(45deg);
  }
}

.notificationContainer {
	border: 2px solid !important;
	border-color: rgb(241, 103, 106) !important;
	animation-duration: 3s !important;
  animation-name: test !important;
}

.showText {
	position: absolute;
	top: 8px;
	right: 25px;
	color: rgb(241, 103, 106) !important;
	font-family: 'Gotham Bold';
	font-size: 9px;
}

.hideText {
	display: none;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: rgb(241, 103, 106) !important;
  border-radius: 50%;
  display: inline-block;
	position: absolute;
	top: -20px;
	right: -35px;
	animation-name: grow;
	animation-duration: 2s;
}

@keyframes grow {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(4.333);
    }
}



// .TaskItemContainer {
//   background: white;
//   margin-bottom:2px;
//   margin:auto 10;
// }

// .TaskItemContainer button:focus{
//   outline:0;
// }

// .TaskItemContainerSelected {
//   background: #cccccc;
//   margin-bottom:2px;
//   margin:auto 10;
// }

// .TaskItemContainerSelected button:focus{
//   outline:0;
// }

// .TaskItemPendingContainer{
//   background: #009fe3;
//   margin-bottom:2px;
//   margin:auto 10;
// }

// .TaskItemPendingContainer button:focus{
//   outline:0;
// }

// .TaskItemTitle{
//   font-weight: bold;
//   font-size: 14px;
//   text-align: left;
//   margin-bottom: 4px;
//   max-width: 100%;
//   word-break: break-all;
// }

// .TaskItemTitlePending{
//   font-weight: bold;
//   font-size: 14px;
//   text-align: left;
//   margin-bottom: 4px;
//   color:white;
// }


// .TaskItemState{
//   font-size: 12px;
//   width:50%;
//   text-align: left;
// }

// .TaskItemCreated{
//   margin:10px;
//   min-width:30%;
// }

// .TaskItemArrow{
//   width:26px;
//   height:26px;
// }

// .TaskItemDetailsContainer {
//   flex:1;
//   text-align: left;
// }


// .TaskItemButton {
//   display:flex;
//   background:none;
//   border: none;
//   width:100%;
//   padding:8px;
//   align-items: center;
// }
