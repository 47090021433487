.SignInForm {
  text-align: center;
  display:flex;
  flex-direction: column;
  width: max-content;
}

.logoWhite {
  color: #fff;
}

.gothamBold {
  font-family: "Gotham Bold";
}

.ForgotPasswordButton {
  flex:1;
  margin-top: 10px;
  text-align: right;
  background:none;
  border: none !important;
  color:white;
}

.ForgotPasswordButton:active{
  border: none;
  outline: none;
}

.ForgotPasswordButton:focus{
  border: none;
  outline: none;
}

.SignInLogo{
  align-self: center;
}

.SignInSpacerSmall{
  height:4px;
}

.SignInSpacer{
  height:20px;
}

.installText {
  font-family: "Gotham Bold";
  font-size: 10px;
}
