.MessageInputContainer{
  background:#efefef;
  /* height:50px; */
  display:flex;
  align-items: center;
  z-index: 2;
}

.MessageInputContainer input{
  flex:1;
}

.MessageInputContainer img{
  width:30px;
  height:30px;
}

.FileSystem{
  display: none;
}

.MessageInputContainer button{
  display:flex;
  width:40px;
  height:40px;
  align-content: center;
  justify-content: center;
  margin:4px;
  background: none;
  border:none;
}

.MessageInputContainer img{
  width:40px;
  height:40px;
  object-fit: contain;
}

.MessageInputContainer button:active{
  border:none;
  background: #009fe3;
  border-radius: 10px;
}

.MessageInputContainer button:focus{
  outline:0;
}

.AttachButton {
	color: #666 !important;
	font-size: 32px;
	cursor: pointer;
}
.SendButton {
	color: #666 !important;
	font-size: 32px;
	cursor: pointer;
}
.tiny {
	font-size: 9px;
	position: absolute;
	bottom: 30px;
}
