.userProfileContainer{
  min-width: 300px;
  color:#fff;
  display:flex;
  flex-direction: column;
  padding:20px;
  position: fixed;
  left:0;
  top:0;
  width: 300px;
  height: 100%;
  z-index: 11;
  transition: all .3s ease;
  overflow-x: hidden;
  padding: 15px;
	background-image: url("https://www.heyjudeapp.com/img/firstBanner.jpg");
  @media (min-width:1200px) {
    position: relative;
    width: 20%;
    max-width: 20%;
    // border-right: 2px solid black;
  }
}

.userProfileHide{
  transform: translateX(-100%);
  @media (min-width:1200px) {
    transform: none;
  }
}

.userLogout{
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 10px;
}

.userAvatarContainer{
  display: flex;
  justify-content: center;
  min-width: max-content;
  border-radius: 5px;
  color: #000;
}


.UserLabel{
  font-weight: bold;
}

.UserLabelSpacer{
  height:10px;
}

.ProfileSpacer{
  flex:1
}

.userLogout{
  color: #FFF;
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
  font-size: 12px !important;
  font-weight: bold !important;
}

.userCloseButton{
  color: #FFF;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 12px !important;
  font-weight: bold !important;
}

.userImageWrapp{
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  position: relative;
}

.userImage{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.userImageSelect{
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}

.PoweredBy {
	width: 50px;
	position: absolute;
	bottom: 10px;
	right: 70px;
}

.username {
  font-size: 18px !important;
  font-family: "Gotham Bold";
	color: #fff;
	padding-bottom: 8px;
}

.userphone {
	color: #fff;
	font-size: 11px !important;
	font-family: "Gotham";
	color: #fff;
}

.iconUser {
	font-size: 34px;
	color: #fff;
}

.ccList {
  font-size: 10px !important;
}

.terms {
	font-size: 10px !important;
	color: #fff;
}

.panelButtonSmall {
	color: #fff;
	font-family: "Gotham";
	font-size: 12px;
	padding: 10px;
	border-radius: 10px;
	background-color: #00abef;
}

.panelButtonSmallDark {
	color: #fff;
	font-family: "Gotham";
	font-size: 12px;
	padding: 10px;
	border-radius: 10px;
	background-color: #000;
}


.header {
	padding: 15px;
	border-radius: 10px;
	background-color: #00abef;
}

.footer {
	position: absolute;
	bottom: 0px;
	left: 0px;
}

.actions {
	margin-top: 15px;
	padding-top: 15px;
	font-size: 12px;
}

.noBorder {
	border: none;
}

.light {
	color: #fff;
}
