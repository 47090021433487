.NewTaskContainer{
  padding: 10px;
  display:flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 99;
	border-top: 2px solid #00abef;
	background-color: #efefef;
  /* justify-content: center; */
}

.NTClose {
  position: absolute;
  right: 5px;
  top: 5px;
}

.FileSystem{
  display: none;
}

.NewTaskBlock{
  background-color: #efefef !important;
  width: 100%;
  min-width: 300px;
  max-width: 360px;
  padding: 30px 10px;
  border-radius: 5px;
  position: relative;
  display:flex;
  flex-direction: column;
  color: #ffc520 !important;
  @media (min-width:576px) {
    padding: 40px 30px;
  }
}

.NewTaskTitleContainer{
  display:flex;
  align-items: center;
}

.NewTaskTitle{
  font-weight: bold;
  text-align: center;
  flex:1;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.NewTaskInputContainer{
  display:flex;
  align-items: flex-end;
}


.NewTaskContainer img{
  object-fit: contain;
}

.NewTaskContainer button:active{
  border:none;
  background: #009fe3;
  border-radius: 10px;
}

.NewTaskContainer button:focus{
  outline:0;
}

.InputWrapper{
  display:flex;
  flex:1;
  margin:0px 10px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #81BF3F;
  padding: 2px;
}

.NewTaskInput{
  flex:1;
  font-size: 16px;
  resize: none;
  outline:none;
  overflow: hidden;
  background-color: #fff;
  border: none;
  border-left: 2px solid  #00abef !important;
  font-family: 'Gotham';
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  color: #000;
}

.NewTaskInputTA{
  min-height: 150px;
  flex:1;
  font-size: 16px;
  resize: none;
  outline:none;
  overflow: hidden;
  background-color: #fff;
  border-left: 2px solid #000;
  font-family: 'Gotham';
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  color: #000;
  font-weight: bold;
}

.NewTaskInput::placeholder {
  color: #000;
  font-weight: bold;
  font-size: 14px;
  font-family: "Gotham";
}

.NewTaskInputTA::placeholder {
  color: #efefef;
  font-weight: bold;
  font-size: 12px;
}

.NewTaskMediaAttachmentList{
  display:flex;
  /* margin-top: 4px; */
  padding:10px;
  overflow:hidden;
  align-items: center;
  border-radius: 6px;
  margin-top: 20px;

}

.NewTaskMediaAttachmentContainer {
  flex:1;
  display:flex;
  flex-direction: row;
  overflow: auto;
  margin-right: 15px;
  flex-wrap: wrap;
  justify-content: center;
}

.NewTaskMediaAttachmentListItem{
  display:flex;
  background:#2e2e2e;
  margin:2px;
  border:2px solid white;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
}

.NewTaskHeader {
	color:  #00abef !important;
	font-size: 18px;
  font-family: "Gotham Bold";
}
.NewTaskDescription{
	color: #fff;
	font-size: 12px;
}
.NewTaskFileButton {
	font-size: 24px;
	position: absolute;
	width: 50px !important;
	position: absolute;
	width: 30px;
	bottom: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: #fff !important;
	border: none;
	border-radius: 30px;
	font-weight: bold;
	color: #948c82;
	text-transform: uppercase;
}
.NewTaskAudioButton {
	font-size: 24px;
	position: absolute;
	width: 50px;
	bottom: 10px;
	left: 50px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: #fff !important;
	border: none;
	border-radius: 30px;
	font-weight: bold;
	color: #99CEC5;
	text-transform: uppercase;
}
.NewTaskSubmit {
	padding-top: 10px;
	padding-bottom: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: #00abef;
	border-radius: 5px;
	font-weight: bold;
	font-size: 14px;
	color: #fff;
	width: 150px !important;
	position: absolute;
	bottom: 10px;
	right: 10px;
	height: 50px;
	font-family: "Gotham Bold";
	border: none !important;
}
