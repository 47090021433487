.button{
    height: 40px;
    padding: 0 15px;
    border-radius: 6px;
    border: none;
    font-size: 14px;
    cursor: pointer;
    opacity: 1;
    font-weight: bold;
}

.textonly {
    width: max-content;
    height: max-content;
    border-radius: 0;
    padding: 0;
    background-color: transparent !important;
  	border: none !important;
  	color: #fff !important;
  	font-size: 14px !important;
}

.textonly_small {
    width: max-content;
    height: max-content;
    border-radius: 0;
    padding: 0;
    background-color: transparent !important;
  	border: none !important;
  	color: #fff !important;
  	font-size: 10px !important;
}

.textonly_dark {
	width: max-content;
	height: max-content;
	border-radius: 0;
	padding: 0;
	background-color: transparent !important;
	border: none !important;
	color: #000 !important;
	font-size: 14px !important;
}

.textonly_close {
	width: max-content;
	height: max-content;
	border-radius: 0;
	padding: 0;
	background-color: transparent !important;
	border: none !important;
	color: #000 !important;
	font-size: 14px !important;
}

.empty{
    background-color: transparent;
    border-radius: 6px;
		border: none !important;
}
