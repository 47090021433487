.TaskCreationOptionsContainer{
  height:80px;
  padding-top:1px;
  text-align: center;
}

.TaskOptionSpacer{
  width:2px;
}

.TaskCreationOption{
  border:2px solid green;
  text-align: center;
  height:30px;
  width: 150px;
  font-weight: bold;
  background:#1991EB;
  color:#fff;
  margin-bottom: 15px;
  margin-top: 15px;
  border-radius: 5px;
  border: none;
  
}

.TaskCreationOption:active{
  outline:none;
  background:#009fe3;
  color:#ffffff;
}

.TaskCreationOption:focus{
  outline:0;
} 

.wrapper-creation-options {
	position: fixed;
	bottom: 0px;
}

.stupid-fn-button {
	position: fixed;
	bottom: 10px;
	z-index: 999;
}