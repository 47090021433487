$default_yellow: #ffc520;
$default_white: #FFFFFF;
$default_black: #000;
$default_gray: #1D1D1D;
$default_blue_bahama: #CCC;
$default_biscay: #000;
$default_malibu: #99CEC5;

@mixin color($name, $val) {
    &-#{$name}{
        color: $val !important;
    }
}

.color{
    @include color('white', $default_white);
    @include color('black', $default_black);
    @include color('default-gray', $default_gray);
}
