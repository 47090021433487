.SubscriptionWindow {
  padding: 20px;
  position: absolute;
  color: white;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  width: 100vw;
  height:100vh;
  background-image: url("https://www.heyjudeapp.com/img/firstBanner.jpg");
  z-index: 100;
}

.SubscriptionContainer {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
	width: 90%;
}

.SubscriptionItemHeading {
  font-weight: bold;
  font-size: 18px !important;
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}

.SubscriptionItem {
  padding:20px;
  margin:10px;
	background-color: #00abef;
  border-radius: 8px;
}

.SubscriptionItem Button{
  margin-top: 10px;
}

.SubscriptionItemTitle {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
  font-family: "Gotham Bold";
}

.SubscriptionItemPrice {
  margin-bottom: 10px;
  font-size: 11px;
}

.SubscriptionItemText {
  font-size: 11px;
}

.SubscriptionNextText {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  margin-top:16px;
  margin-bottom:6px;
}

.SubscriptionNextTextToo {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  margin-top:16px;
  margin-bottom:6px;
	text-align: right;
}

.SubscriptionCheck {
  // margin:100px;
  width:24px;
  height:24px;
}
