.MediaAttachmentList{
  
  /* margin-top: 4px; */
  padding:10px;
  overflow:auto;
  align-items: center;
  background:#009fe3;
  max-height: 250px;
  @media (min-width:768px) {
    max-height: 450px;
  }
}

.MediaAttachmentContainer {
  flex:1;
  display:flex;
  flex-direction: row;
  overflow: auto;
  flex-wrap: wrap;
  justify-content: center;
}

.MediaAttachmentListItem{
  display:flex;
  background:#2e2e2e;
  margin:6px;
  border:2px solid white;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  height:70px;
  width:70px;
  overflow: hidden;
}

.MediaAttachmentListItem img {
  height:70px;
  width:70px;
  border-radius: 4px;
  object-fit: contain;
}

