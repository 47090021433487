.backdropStyle {
  position: absolute;
  top: 0;
  left: 0;
  background: #99CEC5;
  padding: 20px;
  z-index: 999;
  width: 100%;
  height: 100%;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: fixed;
	opacity: 1;
  overflow: visible;
  overflow-y: auto;
}

.modalStyle	{
  height: 90vh;
  overflow-y: auto;
  padding: 20px;
  background: #ccc;
}

.closeButton {
	position: fixed;
	top: 40px;
	right: 50px;
	background: none;
	border: 1px solid #fff;
	color: #000;
}
