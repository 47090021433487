.MMenu_P {
  font-size: 16px;
  margin-top: 5px;
  padding-top: 5px;
}

.Hey_Title {
  font-size: 30px;
  line-height: 60px;
  color: #000;
}

.Hey_Copy {
	font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 200;
  line-height: 36px;
}

.MMenu_Hey {
	margin-top: 10px;
	color: #fff;
}

.legals {
  color: #fff;
  padding: 5px;
  font-size: 12px !important;
}
