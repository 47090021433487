html {
  box-sizing: border-box;
  font-family: sans-serif;
}

body{
  line-height: 1;
  font-weight: normal;
  margin: 0;
  padding: 0;
  // background: #fff;
  font-family: 'Gotham';
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h1,h2,h3,h4,h5,h6,p{
  margin: 0;
  padding: 0;
}

input, a, button, select, div, textarea{
  text-decoration: none!important;
  outline: none!important;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  &:active{
      outline: none;
      -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  }
  &:hover{
      outline: none;
      -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  }
}


*,
*::before,
*::after {
  box-sizing: inherit
}










.AppContainer {
  overflow: hidden;
  position:absolute;
  right:0px; /*will be 0*/
  left:0px;
  top:0px;
  bottom:0px;
}

.PageContainer {
  position:absolute;
  right:0px;
  left:0px;
  top:0px;
  bottom:0px;
  flex-direction: column;
}

.ContentContainer {
  position: relative;
  top: 30%;
  transform: translateY(-50%);
}

.CenterTitle {
  text-align: center;
  padding:10px;
}

.MainAppContainer {
  height:100%;
}
