
.MyPage{
  width:100vw;
  height:100vh;
  flex-direction: column;
}

.MyInnerPage{
  width:100vw;
  height: calc(100% - 60px);
  display:flex;
  flex-direction: column;
}

.MyColumnContainer{
  display:flex;
  flex-direction: row;
  flex:1;
  overflow:hidden;
  position: relative;
  // transition: all .3s ease;
}

.taskListColumn{
  width: 100%;
  display:flex;
  flex-direction: column;
  @media (min-width:992px) {
    border-right: 2px solid black;
    width: 25%;
    min-width: 350px;
  }
}

.chatArea{
  flex:1;
  display:flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 5;
  transform: translateX(-110vw);
  transition: transform .3s ease;
  @media (min-width:992px) {
    width: auto;
    position: relative;
    transform: none;
  }
}

.show{
  transform: translateX(0);
  @media (min-width:992px) {
    transform: none;
  }
}

/* .MyInput{
  margin-top:5px;
  padding:5px;
  border: 1px dashed black;
} */