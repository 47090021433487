@font-face {
    font-family: 'fontello';
    src:
        local('fontello'),
        local('fontello'),
        url('../Assets/Fonts/fontello.eot'),
        url('../Assets/Fonts/fontello.eot#iefix') format('embedded-opentype'),
        url('../Assets/Fonts/fontello.woff2') format('woff2'),
        url('../Assets/Fonts/fontello.woff') format('woff'),
        url('../Assets/Fonts/fontello.ttf') format('truetype'),
        url('../Assets/Fonts/fontello.svg#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: 'Gotham';
    src:
        local('Gotham'),
        local('Gotham'),
        url('../Assets/Fonts/MyriadPro-Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: 'Gotham Bold';
    src:
        local('Gotham Bold'),
        local('Gotham Bold'),
        url('../Assets/Fonts/MyriadPro-Bold.otf') format('truetype');
    font-weight: bold;
    font-style: bold;
  }

   [class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-left:before { content: '\e800'; } /* '' */
  .icon-logout:before { content: '\e805'; } /* '' */
  .icon-paper-plane:before { content: '\f1d8'; } /* '' */
  .icon-doc-add:before { content: '\e801'; }
  .icon-pencil:before { content: '\e802'; } /* '' */
  .icon-credit-card:before { content: '\e803'; }
  .icon-cancel:before { content: '\e804'; }
