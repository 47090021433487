.TaskToggleContainer{
  display:flex;
  padding:1px 0px;
  

}

.TaskToggleContainer button {
margin-right: 10px;
}

.TaskToggleContainer button:active {
  border:none;
}

.TaskToggleContainer button:focus{
  outline:0;
} 

.TaskToggleButtonActive {
  width:50%;
  height: 50px;
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  margin              : 0;
  left                : 1px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Muli", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 14.0px;
  color               : rgba(255, 255, 255, 1.0);
  text-align          : center;
  line-height         : 18.0px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: #FACA46;
  border-bottom-width: 3px;
}

.TaskToggleButtonInActive {
  width:50%;
  height: 50px;
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  margin              : 0;
  left                : 1px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Muli", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 14.0px;
  color               : rgba(255, 255, 255, 1.0);
  text-align          : center;
  line-height         : 18.0px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: #626870;
  border-bottom-width: 3px;
}

.TaskListSection{
  z-index: -200;
}