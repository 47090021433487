.SignInForm {
  text-align: center;
  display:flex;
  flex-direction: column;
  width: max-content;
  background: rgba(38, 39, 38, 0.5);
  border-radius: 10px;
  align-items: center;

}

.ForgotPasswordButton{
  flex:1;
  margin-top: 0px;
  text-align: right;
  background:none;
  border:none;
  color:white;
}

.ForgotPasswordButton:active{
  border: none;
  outline: none;
}

.ForgotPasswordButton:focus{
  border: none;
  outline: none;
}

.SignInLogo{
  align-self: center;
  width: 100px;
  margin: 10px 10px;
}

.SignInSpacerSmall{
  height:10px;
}

.SignInSpacer{
  height:20px;
}

.UserName{
  width:250px;
}

.Password{
  width:250px;
}

.SButton{
  width:210px;
}